export const BRANCH_OFFICES_INDEX = 	"branch-offices.index";
export const BRANCH_OFFICES_STORE = 	"branch-offices.store";
export const BRANCH_OFFICES_SHOW = 	"branch-offices.show";
export const BRANCH_OFFICES_UPDATE = 	"branch-offices.update";
export const BRANCH_OFFICES_DESTROY = 	"branch-offices.destroy";
export const ADMIN_USERS_INDEX = 	"admin-users.index";
export const ADMIN_USERS_STORE = 	"admin-users.store";
export const ADMIN_USERS_SHOW = 	"admin-users.show";
export const ADMIN_USERS_UPDATE = 	"admin-users.update";
export const ADMIN_USERS_DESTROY = 	"admin-users.destroy";
export const USER_PERMISSIONS_INDEX = 	"user-permissions.index";
export const USER_PERMISSIONS_STORE = 	"user-permissions.store";
export const USER_PERMISSIONS_DESTROY = 	"user-permissions.destroy";
export const CLIENT_USERS_INDEX = 	"client-users.index";
export const CLIENT_USERS_STORE = 	"client-users.store";
export const CLIENT_USERS_SHOW = 	"client-users.show";
export const CLIENT_USERS_UPDATE = 	"client-users.update";
export const CLIENT_USERS_DESTROY = 	"client-users.destroy";
export const UPDATE_USER_DETAILS_STORE = 	"update-user-details.store";
export const UPDATE_USER_DETAILS_UPDATE = 	"update-user-details.update";
export const USER_BRANCH_OFFICES_INDEX = 	"user-branch-offices.index";
export const USER_BRANCH_OFFICES_STORE = 	"user-branch-offices.store";
export const USER_BRANCH_OFFICES_DESTROY = 	"user-branch-offices.destroy";
export const AVAILABLE_BOX = 	"available.box";
export const DISABLE_BOX = 	"disable.box";
export const OPERATORS_INDEX = 	"operators.index";
export const OPERATORS_SHOW = 	"operators.show";
export const BROKER_OPERATOR_CARDS_INDEX = 	"broker-operator-cards.index";
export const BROKER_OPERATOR_CARDS_SHOW = 	"broker-operator-cards.show";
export const BROKER_OPERATOR_USERS_INDEX = 	"broker-operator-users.index";
export const BROKER_OPERATOR_USERS_STORE = 	"broker-operator-users.store";
export const BROKER_OPERATOR_USERS_SHOW = 	"broker-operator-users.show";
export const BROKER_OPERATOR_USERS_UPDATE = 	"broker-operator-users.update";
export const BROKER_OPERATOR_USERS_DESTROY = 	"broker-operator-users.destroy";
export const CONVEYORS_INDEX = 	"conveyors.index";
export const CONVEYORS_SHOW = 	"conveyors.show";
export const BROKER_CONVEYOR_CARDS_INDEX = 	"broker-conveyor-cards.index";
export const BROKER_CONVEYOR_CARDS_SHOW = 	"broker-conveyor-cards.show";
export const BROKER_CONVEYOR_USERS_INDEX = 	"broker-conveyor-users.index";
export const BROKER_CONVEYOR_USERS_STORE = 	"broker-conveyor-users.store";
export const BROKER_CONVEYOR_USERS_SHOW = 	"broker-conveyor-users.show";
export const BROKER_CONVEYOR_USERS_UPDATE = 	"broker-conveyor-users.update";
export const BROKER_CONVEYOR_USERS_DESTROY = 	"broker-conveyor-users.destroy";
export const UNITS_INDEX = 	"units.index";
export const SERVICES_INDEX = 	"services.index";
export const SERVICES_SHOW = 	"services.show";
export const SERVICE_EXIT_PLACE_PRICES_INDEX = 	"service-exit-place-prices.index";
export const SERVICE_EXIT_PLACE_PRICES_SHOW = 	"service-exit-place-prices.show";
export const SERVICE_EXIT_PLACES_INDEX = 	"service-exit-places.index";
export const SERVICE_EXIT_PLACES_SHOW = 	"service-exit-places.show";
export const SERVICE_INFORMATIONS_SHOW = 	"service-informations.show";
export const SERVICE_TRAVEL_DATES_INDEX = 	"service-travel-dates.index";
export const SERVICE_TRAVEL_DATES_SHOW = 	"service-travel-dates.show";
export const SERVICE_VIDEO_SHOW = 	"service-video.show";
export const SERVICE_TRAVEL_DATE_UNITS_INDEX = 	"service-travel-date-units.index";
export const SERVICE_TRAVEL_DATE_UNITS_SHOW = 	"service-travel-date-units.show";
export const HOTEL_RESERVATIONS_INDEX = 	"hotel-reservations.index";
export const HOTEL_RESERVATIONS_STORE = 	"hotel-reservations.store";
export const HOTEL_RESERVATIONS_SHOW = 	"hotel-reservations.show";
export const HOTEL_RESERVATIONS_UPDATE = 	"hotel-reservations.update";
export const HOTEL_RESERVATIONS_DESTROY = 	"hotel-reservations.destroy";
export const CIRCUIT_RESERVATIONS_INDEX = 	"circuit-reservations.index";
export const CIRCUIT_RESERVATIONS_STORE = 	"circuit-reservations.store";
export const CIRCUIT_RESERVATIONS_SHOW = 	"circuit-reservations.show";
export const CIRCUIT_RESERVATIONS_UPDATE = 	"circuit-reservations.update";
export const CIRCUIT_RESERVATIONS_DESTROY = 	"circuit-reservations.destroy";
export const DAYTRIPS_RESERVATIONS_INDEX = 	"daytrips-reservations.index";
export const DAYTRIPS_RESERVATIONS_STORE = 	"daytrips-reservations.store";
export const DAYTRIPS_RESERVATIONS_SHOW = 	"daytrips-reservations.show";
export const DAYTRIPS_RESERVATIONS_UPDATE = 	"daytrips-reservations.update";
export const DAYTRIPS_RESERVATIONS_DESTROY = 	"daytrips-reservations.destroy";
export const TOURS_RESERVATIONS_INDEX = 	"tours-reservations.index";
export const TOURS_RESERVATIONS_STORE = 	"tours-reservations.store";
export const TOURS_RESERVATIONS_SHOW = 	"tours-reservations.show";
export const TOURS_RESERVATIONS_UPDATE = 	"tours-reservations.update";
export const TOURS_RESERVATIONS_DESTROY = 	"tours-reservations.destroy";
export const INTERNATIONAL_RESERVATIONS_INDEX = 	"international-reservations.index";
export const INTERNATIONAL_RESERVATIONS_STORE = 	"international-reservations.store";
export const INTERNATIONAL_RESERVATIONS_SHOW = 	"international-reservations.show";
export const INTERNATIONAL_RESERVATIONS_UPDATE = 	"international-reservations.update";
export const INTERNATIONAL_RESERVATIONS_DESTROY = 	"international-reservations.destroy";
export const FLIGHT_RESERVATIONS_INDEX = 	"flight-reservations.index";
export const FLIGHT_RESERVATIONS_STORE = 	"flight-reservations.store";
export const FLIGHT_RESERVATIONS_SHOW = 	"flight-reservations.show";
export const FLIGHT_RESERVATIONS_UPDATE = 	"flight-reservations.update";
export const FLIGHT_RESERVATIONS_DESTROY = 	"flight-reservations.destroy";
export const OTHER_SERVICES_RESERVATIONS_INDEX = 	"other-services-reservations.index";
export const OTHER_SERVICES_RESERVATIONS_STORE = 	"other-services-reservations.store";
export const OTHER_SERVICES_RESERVATIONS_SHOW = 	"other-services-reservations.show";
export const OTHER_SERVICES_RESERVATIONS_UPDATE = 	"other-services-reservations.update";
export const OTHER_SERVICES_RESERVATIONS_DESTROY = 	"other-services-reservations.destroy";
export const RESERVATION_HABITATIONS_UPDATE = 	"reservation-habitations.update";
export const RESERVATION_HABITATIONS_DESTROY = 	"reservation-habitations.destroy";
export const RESERVATION_PEOPLES_UPDATE = 	"reservation-peoples.update";
export const RESERVATION_PEOPLES_DESTROY = 	"reservation-peoples.destroy";
export const RESERVATION_PEOPLE_SEATS_STORE = 	"reservation-people-seats.store";
export const RESERVATION_PEOPLE_SEATS_DESTROY = 	"reservation-people-seats.destroy";
export const RESERVATION_PASSES_STORE = 	"reservation-passes.store";
export const RESERVATION_PASSES_UPDATE = 	"reservation-passes.update";
export const RESERVATION_NOTES_STORE = 	"reservation-notes.store";
export const RESERVATION_NOTES_SHOW = 	"reservation-notes.show";
export const RESERVATION_NOTES_DESTROY = 	"reservation-notes.destroy";
export const PAYMENT_RESERVATIONS_INDEX = 	"payment-reservations.index";
export const PAYMENT_RESERVATIONS_STORE = 	"payment-reservations.store";
export const PAYMENT_RESERVATIONS_SHOW = 	"payment-reservations.show";
export const PAYMENT_RESERVATIONS_DESTROY = 	"payment-reservations.destroy";
export const PAYMENT_TO_BROOKERS_INDEX = 	"payment-to-brookers.index";
export const PAYMENT_TO_BROOKERS_STORE = 	"payment-to-brookers.store";
export const BRANCH_BOXES_INDEX = 	"branch-boxes.index";
export const BOX_CUTS_INDEX = 	"box-cuts.index";
export const BOX_CUTS_STORE = 	"box-cuts.store";
export const BOX_CUTS_SHOW = 	"box-cuts.show";
export const PAYMENT_INCOME_STORE = 	"payment-income.store";
export const PAYMENT_INCOME_DESTROY = 	"payment-income.destroy";
export const PAYMENT_EXPENSES_STORE = 	"payment-expenses.store";
export const PAYMENT_EXPENSES_DESTROY = 	"payment-expenses.destroy";
export const SAVINGS_INDEX = 	"savings.index";
export const SAVING_INCOME_STORE = 	"saving-income.store";
export const SAVING_INCOME_DESTROY = 	"saving-income.destroy";
export const SAVING_EXPENSES_STORE = 	"saving-expenses.store";
export const SAVING_EXPENSES_DESTROY = 	"saving-expenses.destroy";
export const EXPENSE_TO_BANK = 	"expense.to-bank";
export const PROMOTIONS_INDEX = 	"promotions.index";
export const PROMOTIONS_STORE = 	"promotions.store";
export const PROMOTIONS_SHOW = 	"promotions.show";
export const PROMOTIONS_UPDATE = 	"promotions.update";
export const PROMOTIONS_DESTROY = 	"promotions.destroy";
export const AGENCY_CARDS_INDEX = 	"agency-cards.index";
export const AGENCY_CARDS_STORE = 	"agency-cards.store";
export const AGENCY_CARDS_SHOW = 	"agency-cards.show";
export const AGENCY_CARDS_UPDATE = 	"agency-cards.update";
export const AGENCY_CARDS_DESTROY = 	"agency-cards.destroy";
export const BANK_PAYMENTS_INDEX = 	"bank-payments.index";
export const BANK_INCOME_STORE = 	"bank-income.store";
export const BANK_INCOME_DESTROY = 	"bank-income.destroy";
export const BANK_EXPENSES_STORE = 	"bank-expenses.store";
export const BANK_EXPENSES_DESTROY = 	"bank-expenses.destroy";
export const PROFILES_INDEX = 	"profiles.index";
export const PROFILES_STORE = 	"profiles.store";
export const PROFILES_UPDATE = 	"profiles.update";
export const PROFILES_DESTROY = 	"profiles.destroy";
export const PROFILE_MENUS_INDEX = 	"profile-menus.index";
export const PROFILE_MENUS_STORE = 	"profile-menus.store";
export const PROFILE_MENUS_DESTROY = 	"profile-menus.destroy";
export const PROFILE_MENU_ACTIONS_STORE = 	"profile-menu-actions.store";
export const PROFILE_MENU_ACTIONS_DESTROY = 	"profile-menu-actions.destroy";
export const ADMIN_UPDATE_PROFILE = 	"admin-update.profile";
export const ADMIN_PASSWORD_UPDATE_PROFILE = 	"admin-password-update.profile";
export const PACKAGED_RESERVATIONS_INDEX = 	"packaged-reservations.index";
export const PACKAGED_RESERVATIONS_STORE = 	"packaged-reservations.store";
export const PACKAGED_RESERVATIONS_SHOW = 	"packaged-reservations.show";
export const PACKAGED_RESERVATIONS_UPDATE = 	"packaged-reservations.update";
export const PACKAGED_RESERVATIONS_DESTROY = 	"packaged-reservations.destroy";
export const TRANSFER_RESERVATIONS_INDEX = 	"transfer-reservations.index";
export const TRANSFER_RESERVATIONS_STORE = 	"transfer-reservations.store";
export const TRANSFER_RESERVATIONS_SHOW = 	"transfer-reservations.show";
export const TRANSFER_RESERVATIONS_UPDATE = 	"transfer-reservations.update";
export const TRANSFER_RESERVATIONS_DESTROY = 	"transfer-reservations.destroy";
export const LOCKED_RESERVATIONS_INDEX = 	"locked-reservations.index";
export const LOCKED_RESERVATIONS_STORE = 	"locked-reservations.store";
export const LOCKED_RESERVATIONS_SHOW = 	"locked-reservations.show";
export const LOCKED_RESERVATIONS_UPDATE = 	"locked-reservations.update";
export const LOCKED_RESERVATIONS_DESTROY = 	"locked-reservations.destroy";
export const GROUP_RESERVATIONS_INDEX = 	"group-reservations.index";
export const GROUP_RESERVATIONS_STORE = 	"group-reservations.store";
export const GROUP_RESERVATIONS_SHOW = 	    "group-reservations.show";
export const GROUP_RESERVATIONS_UPDATE = 	"group-reservations.update";
export const GROUP_RESERVATIONS_DESTROY = 	"group-reservations.destroy";

export const RESERVATION_ADDITIONAL_PAYMENT = "payment-additionals.store";
export const AGENCY_CONFIGURATIONS_INDEX = "agency-configurations.index";

//Reports

export const REPORT_VIEW_INFORMATION = "report.information-dashboard";

export const BRANCH_OFFICES_GALLERY = "agency-gallery.index";
export const BRANCH_OFFICES_GALLERY_STORE = "agency-gallery.store";
export const BRANCH_OFFICES_GALLERY_UPDATE = "agency-gallery.update";
export const BRANCH_OFFICES_GALLERY_DELETE = "agency-gallery.delete";

export const BRANCH_OFFICES_TEAM = "agency-gallery.index";
export const BRANCH_OFFICES_TEAM_STORE = "agency-gallery.store";
export const BRANCH_OFFICES_TEAM_UPDATE = "agency-gallery.update";
export const BRANCH_OFFICES_TEAM_DELETE = "agency-gallery.delete";