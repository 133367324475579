<template>
<!-- Sidebar -->
<div class="sidebar" id="sidebar">
    <div class="sidebar-logo"> 
        <router-link to="/admin/dashboard">
            <img :src="config.image" class="img-fluid" alt="">
            <label for="" style="font-size: 1rem;vertical-align: -webkit-baseline-middle;color: white;padding-left: 10px;">{{ config.name }}</label>
        </router-link>
    </div>
    <div class="sidebar-inner">
        <vue-custom-scrollbar class="scroll-area"  :settings="settings" @ps-scroll-y="scrollHanle">
        <div id="sidebar-menu" class="sidebar-menu">
            <ul>
                <li v-bind:class="{'active': currentPath == ROUTE_HOME}" v-if="ACCESS_MENU(HOME)">
                    <router-link to="/admin/dashboard"><i :class="ACCESS_MENU(HOME).menu.icon"></i> <span>{{ ACCESS_MENU(HOME).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(BRANCH_OFFICE)" v-bind:class="{'active': [ROUTE_BRANCH_OFFICES, ROUTE_BRANCH_OFFICESADD, ROUTE_BRANCH_OFFICESEDIT, ROUTE_BRANCH_WORKINGHOURS].includes(currentPath) }">
                    <router-link to="/admin/sucursales"><i :class="ACCESS_MENU(BRANCH_OFFICE).menu.icon"></i> <span>{{ ACCESS_MENU(BRANCH_OFFICE).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(USERS)" class="submenu" @click="toggleMenu(1)">
                    <a href="javascript:;" v-bind:class="{'active': [ 
                        ROUTE_USER_CLIENTS, ROUTE_USER_ADMIN, ROUTE_USER_FREELANCERS, ROUTE_USER_AGENCIES, 
                        ROUTE_USER_ADD, ROUTE_USER_EDIT, ROUTE_USER_PERMISSIONS, ROUTE_USER_BRANCHOFFICES ].includes(currentPath), 'subdrop': (openMenu == true && menuActive == 1 ) ? true : false }">
                        <i :class="ACCESS_MENU(USERS).menu.icon"></i> 
                        <span> {{ ACCESS_MENU(USERS).menu.title }} </span> 
                        <span class="menu-arrow"></span>
                    </a>
                    <ul :style="(openMenu == true && menuActive == 1 ) ? 'display:block;' : 'display:none;'">
                        <li v-if="ACCESS_MENU_ACTION(CLIENT_USERS_INDEX)" v-bind:class="{'active': [ ROUTE_USER_CLIENTS ].includes(currentPath) }"><router-link to="/admin/usuarios/clientes">Clientes</router-link></li>
                        <li v-if="ACCESS_MENU_ACTION(ADMIN_USERS_INDEX)" v-bind:class="{'active': [ ROUTE_USER_ADMIN, ROUTE_USER_PERMISSIONS, ROUTE_USER_BRANCHOFFICES ].includes(currentPath)}"><router-link to="/admin/usuarios/administradores">Administradores</router-link></li>
                        <!-- <li v-bind:class="{'active': [ ROUTE_USER_FREELANCERS ].includes(currentPath)}"><router-link to="/admin/usuarios/freelancers">Freelancer</router-link></li> -->
                    </ul>
                </li>
                <li v-if="ACCESS_MENU(OPERATORS)" v-bind:class="{'active': [ ROUTE_OPERATORS, ROUTE_OPERATOR_EDIT ].includes(currentPath)}"> 
                    <router-link to="/admin/operadoras"><i :class="ACCESS_MENU(OPERATORS).menu.icon"></i> <span>{{ ACCESS_MENU(OPERATORS).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(CONVEYORS)" v-bind:class="{'active': [ ROUTE_TRANSPORTS, ROUTE_TRANSPORT_EDIT ].includes(currentPath)}"> 
                    <router-link to="/admin/transportadoras"><i :class="ACCESS_MENU(CONVEYORS).menu.icon"></i> <span>{{ ACCESS_MENU(CONVEYORS).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(UNITS)" v-bind:class="{'active': [ ROUTE_UNITS ].includes(currentPath)}"> 
                    <router-link to="/admin/unidades"><i :class="ACCESS_MENU(UNITS).menu.icon"></i> <span>{{ ACCESS_MENU(UNITS).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(SERVICES)" @click="toggleMenu(2)" class="submenu">
                    <a href="javascript:;" v-bind:class="{'active': [ 
                            ROUTE_SERVICE_HOTELS,ROUTE_SERVICE_CIRCUITS,ROUTE_SERVICE_DAYTRIPS,ROUTE_SERVICE_TOURS,
                            ROUTE_SERVICE_INTERNATIONALS, ROUTE_SERVICE_EDIT, ROUTE_SERVICE_TRAVEL_DATE,
                            ROUTE_SERVICE_EXIT_PLACES, ROUTE_SERVICE_EXIT_PLACE_PRICES, ROUTE_SERVICE_LOCKEDS,ROUTE_SERVICE_LOCKED_SHOW,
                            ROUTE_SERVICE_GROUPS,ROUTE_SERVICE_GROUP_SHOW
                        ].includes(currentPath), 'subdrop': (openMenu == true && menuActive == 2 ) ? true : false }">
                        <i :class="ACCESS_MENU(SERVICES).menu.icon"></i> 
                        <span> {{ ACCESS_MENU(SERVICES).menu.title }} </span> 
                        <span class="menu-arrow"></span>
                    </a>
                    <ul :style="(openMenu == true && menuActive == 2 ) ? 'display:block;' : 'display:none;'">
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_HOTELS ].includes(currentPath) }"><router-link to="/admin/servicios/hoteles"> Hoteles </router-link></li>
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_CIRCUITS ].includes(currentPath) }"><router-link to="/admin/servicios/circuitos"> Circuitos </router-link></li>
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_DAYTRIPS ].includes(currentPath) }"><router-link to="/admin/servicios/pasadias"> Pasadias </router-link></li>
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_TOURS ].includes(currentPath) }"><router-link to="/admin/servicios/tours"> Tours </router-link></li>
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_INTERNATIONALS ].includes(currentPath) }"><router-link to="/admin/servicios/internacionales"> Internacionales </router-link></li>
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_LOCKEDS,ROUTE_SERVICE_LOCKED_SHOW ].includes(currentPath) }"><router-link to="/admin/servicios/bloqueos"> Bloqueos </router-link></li>
                        <li v-bind:class="{'active': [ ROUTE_SERVICE_GROUPS,ROUTE_SERVICE_GROUP_SHOW ].includes(currentPath) }"><router-link to="/admin/servicios/grupos"> Grupos </router-link></li>
                    </ul>
                </li>
                <!-- Cotizador -->
                <li v-if="ACCESS_MENU(SERVICE_QUOTE)" v-bind:class="{'active': [ ROUTE_SERVICE_QUOTE, ROUTE_SERVICE_QUOTE_ADD].includes(currentPath) }"> 
                    <router-link to="/admin/cotizador"><i :class="ACCESS_MENU(SERVICE_QUOTE).menu.icon"></i> <span>{{ ACCESS_MENU(SERVICE_QUOTE).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(RESERVATIONS)" @click="toggleMenu(3)" class="submenu">
                    <a href="javascript:;" v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_HOTEL_INDEX,
                            ROUTE_RESERVATION_HOTEL_CREATE,
                            ROUTE_RESERVATION_HOTEL_SHOW,
                            ROUTE_RESERVATION_HOTEL_EDIT,
                            ROUTE_RESERVATION_CIRCUIT_INDEX,
                            ROUTE_RESERVATION_CIRCUIT_CREATE,
                            ROUTE_RESERVATION_CIRCUIT_SHOW,
                            ROUTE_RESERVATION_CIRCUIT_EDIT,
                            ROUTE_RESERVATION_DAYTRIPS_INDEX,
                            ROUTE_RESERVATION_DAYTRIPS_CREATE,
                            ROUTE_RESERVATION_DAYTRIPS_SHOW,
                            ROUTE_RESERVATION_DAYTRIPS_EDIT,
                            ROUTE_RESERVATION_INTERNATIONALS_INDEX,
                            ROUTE_RESERVATION_INTERNATIONALS_CREATE,
                            ROUTE_RESERVATION_INTERNATIONALS_SHOW,
                            ROUTE_RESERVATION_INTERNATIONALS_EDIT,
                            ROUTE_RESERVATION_FLIGHTS_INDEX,
                            ROUTE_RESERVATION_FLIGHTS_CREATE,
                            ROUTE_RESERVATION_FLIGHTS_SHOW,
                            ROUTE_RESERVATION_FLIGHTS_EDIT,
                            ROUTE_RESERVATION_OTHER_SERVICE_INDEX,
                            ROUTE_RESERVATION_OTHER_SERVICE_CREATE,
                            ROUTE_RESERVATION_OTHER_SERVICE_SHOW,
                            ROUTE_RESERVATION_OTHER_SERVICE_EDIT,
                            ROUTE_RESERVATION_SELECT_SEAT_INDEX,
                            ROUTE_RESERVATION_NOTES,
                            ROUTE_RESERVATION_PEOPLE,
                            ROUTE_RESERVATION_PAYMENTS,
                            ROUTE_RESERVATION_LIST_PAYMENTS,
                            ROUTE_RESERVATION_PAYMENT_TO_BROOKER,
                            ROUTE_RESERVATION_TRANSFER_INDEX,
                            ROUTE_RESERVATION_TRANSFER_CREATE,
                            ROUTE_RESERVATION_TRANSFER_SHOW,
                            ROUTE_RESERVATION_TRANSFER_EDIT,
                            ROUTE_RESERVATION_LOCKEDS_INDEX,
                            ROUTE_RESERVATION_LOCKEDS_CREATE,
                            ROUTE_RESERVATION_LOCKEDS_SHOW,
                            ROUTE_RESERVATION_LOCKEDS_EDIT,
                            ROUTE_RESERVATION_GROUPS_INDEX,
                            ROUTE_RESERVATION_GROUPS_CREATE,
                            ROUTE_RESERVATION_GROUPS_SHOW,
                            ROUTE_RESERVATION_GROUPS_EDIT,
                        ].includes(currentPath), 'subdrop': (openMenu == true && menuActive == 3 ) ? true : false }"><i :class="ACCESS_MENU(RESERVATIONS).menu.icon"></i> <span> {{ ACCESS_MENU(RESERVATIONS).menu.title }} </span> <span class="menu-arrow"></span></a>
                    <ul :style="(openMenu == true && menuActive == 3 ) ? 'display:block;' : 'display:none;'">
                        <li v-if="ACCESS_MENU_ACTION(HOTEL_RESERVATIONS_INDEX)" v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_HOTEL_INDEX,
                            ROUTE_RESERVATION_HOTEL_CREATE,
                            ROUTE_RESERVATION_HOTEL_SHOW,
                            ROUTE_RESERVATION_HOTEL_EDIT ].includes(currentPath) }"><router-link to="/admin/reservaciones/hoteles">Hoteles </router-link></li>
                        <li v-if="ACCESS_MENU_ACTION(CIRCUIT_RESERVATIONS_INDEX)" v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_CIRCUIT_INDEX,
                            ROUTE_RESERVATION_CIRCUIT_CREATE,
                            ROUTE_RESERVATION_CIRCUIT_SHOW,
                            ROUTE_RESERVATION_CIRCUIT_EDIT ].includes(currentPath) }"><router-link to="/admin/reservaciones/circuitos">Circuitos </router-link></li>
                        <li v-if="ACCESS_MENU_ACTION(DAYTRIPS_RESERVATIONS_INDEX)" v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_DAYTRIPS_INDEX,
                            ROUTE_RESERVATION_DAYTRIPS_CREATE,
                            ROUTE_RESERVATION_DAYTRIPS_SHOW,
                            ROUTE_RESERVATION_DAYTRIPS_EDIT ].includes(currentPath) }"><router-link to="/admin/reservaciones/pasadias">Pasadias </router-link></li>
                        <li v-if="ACCESS_MENU_ACTION(TOURS_RESERVATIONS_INDEX)" v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_TOURS_INDEX,
                            ROUTE_RESERVATION_TOURS_CREATE,
                            ROUTE_RESERVATION_TOURS_SHOW,
                            ROUTE_RESERVATION_TOURS_EDIT ].includes(currentPath) }"><router-link to="/admin/reservaciones/tours">Tours </router-link></li>
                        <li v-if="ACCESS_MENU_ACTION(INTERNATIONAL_RESERVATIONS_INDEX)" v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_INTERNATIONALS_INDEX,
                            ROUTE_RESERVATION_INTERNATIONALS_CREATE,
                            ROUTE_RESERVATION_INTERNATIONALS_SHOW,
                            ROUTE_RESERVATION_INTERNATIONALS_EDIT ].includes(currentPath) }"><router-link to="/admin/reservaciones/internacionales">Internacionales </router-link></li>
                        
                        <li v-if="ACCESS_MENU_ACTION(PACKAGED_RESERVATIONS_INDEX)" v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_PACKAGED_INDEX,
                            ROUTE_RESERVATION_PACKAGED_CREATE,
                            ROUTE_RESERVATION_PACKAGED_SHOW,
                            ROUTE_RESERVATION_PACKAGED_EDIT ].includes(currentPath) }"><router-link to="/admin/reservaciones/empaquetados">Empaquetados </router-link></li>

                        <li v-if="ACCESS_MENU_ACTION(FLIGHT_RESERVATIONS_INDEX)" v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_FLIGHTS_INDEX,
                            ROUTE_RESERVATION_FLIGHTS_CREATE,
                            ROUTE_RESERVATION_FLIGHTS_SHOW,
                            ROUTE_RESERVATION_FLIGHTS_EDIT].includes(currentPath) }"><router-link to="/admin/reservaciones/vuelos">Vuelos </router-link></li>
                        <li v-if="ACCESS_MENU_ACTION(OTHER_SERVICES_RESERVATIONS_INDEX)" v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_OTHER_SERVICE_INDEX,
                            ROUTE_RESERVATION_OTHER_SERVICE_CREATE,
                            ROUTE_RESERVATION_OTHER_SERVICE_SHOW,
                            ROUTE_RESERVATION_OTHER_SERVICE_EDIT ].includes(currentPath) }"><router-link to="/admin/reservaciones/otros-servicios">Otros Servicios </router-link></li>
                        <li v-if="ACCESS_MENU_ACTION(OTHER_SERVICES_RESERVATIONS_INDEX)" v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_TRANSFER_INDEX,
                            ROUTE_RESERVATION_TRANSFER_CREATE,
                            ROUTE_RESERVATION_TRANSFER_SHOW,
                            ROUTE_RESERVATION_TRANSFER_EDIT ].includes(currentPath) }"><router-link to="/admin/reservaciones/traslados">Traslados </router-link></li>
                        <li v-if="ACCESS_MENU_ACTION(LOCKED_RESERVATIONS_INDEX)" v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_LOCKEDS_INDEX,
                            ROUTE_RESERVATION_LOCKEDS_CREATE,
                            ROUTE_RESERVATION_LOCKEDS_EDIT,
                            ROUTE_RESERVATION_LOCKEDS_SHOW ].includes(currentPath) }"><router-link to="/admin/reservaciones/bloqueos">Bloqueos </router-link></li>
                        <li v-if="ACCESS_MENU_ACTION(GROUP_RESERVATIONS_INDEX)" v-bind:class="{'active': [ 
                            ROUTE_RESERVATION_GROUPS_INDEX,
                            ROUTE_RESERVATION_GROUPS_CREATE,
                            ROUTE_RESERVATION_GROUPS_EDIT,
                            ROUTE_RESERVATION_GROUPS_SHOW ].includes(currentPath) }"><router-link to="/admin/reservaciones/grupos">Grupos </router-link></li>
                    </ul>
                </li>
                <li v-if="ACCESS_MENU(PAYMENT_MULTIPLE)" v-bind:class="{'active': [ ROUTE_PAYMENT_MULTIPLE, ROUTE_PAYMENT_MULTIPLE_ADD].includes(currentPath) }"> 
                    <router-link to="/admin/pagos-multiples"><i :class="ACCESS_MENU(PAYMENT_MULTIPLE).menu.icon"></i> <span>{{ ACCESS_MENU(PAYMENT_MULTIPLE).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(COUPON)" v-bind:class="{'active': [ ROUTE_COUPONS, ROUTE_COUPON_ADD].includes(currentPath) }"> 
                    <router-link to="/admin/cupones"><i :class="ACCESS_MENU(COUPON).menu.icon"></i> <span>{{ ACCESS_MENU(COUPON).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(BOX)" v-bind:class="{'active': [ 
                        ROUTE_BRANCH_BOXES, ROUTE_BOX_HISTORY, ROUTE_BOX_HISTORY_MOVE_LIST ].includes(currentPath) 
                    }"> 
                    <router-link to="/admin/cajas"><i :class="ACCESS_MENU(BOX).menu.icon"></i> <span>{{ ACCESS_MENU(BOX).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(SAVING)" v-bind:class="{'active': [ 
                        ROUTE_SAVING ].includes(currentPath) 
                    }"> 
                    <router-link to="/admin/ahorros"><i :class="ACCESS_MENU(SAVING).menu.icon"></i> <span>{{ ACCESS_MENU(SAVING).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(PROMOTIONS)" v-bind:class="{'active': [ ROUTE_PROMOTION_FOLDER, ROUTE_PROMOTIONS, ROUTE_PROMOTION_ADD, ROUTE_PROMOTION_EDIT].includes(currentPath) }"> 
                    <router-link to="/admin/promociones"><i :class="ACCESS_MENU(PROMOTIONS).menu.icon"></i> <span>{{ ACCESS_MENU(PROMOTIONS).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(BANKS)" v-bind:class="{'active': [ ROUTE_BANKS, ROUTE_BANK_ADD, ROUTE_BANK_EDIT, ROUTE_BANK_HISTORY_MOVE_LIST ].includes(currentPath) }"> 
                    <router-link to="/admin/cuentas"><i :class="ACCESS_MENU(BANKS).menu.icon"></i> <span>{{ ACCESS_MENU(BANKS).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(PROFILES)" v-bind:class="{'active': [ ROUTE_PROFILE_LIST, ROUTE_PROFILE_ADD ].includes(currentPath) }"> 
                    <router-link to="/admin/perfiles"><i :class="ACCESS_MENU(PROFILES).menu.icon"></i> <span>{{ ACCESS_MENU(PROFILES).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(ACTIVITY)" v-bind:class="{'active': [ ROUTE_ACTIVITY ].includes(currentPath) }"> 
                    <router-link to="/admin/actividades"><i :class="ACCESS_MENU(ACTIVITY).menu.icon"></i> <span>{{ ACCESS_MENU(ACTIVITY).menu.title }}</span></router-link>
                </li>
                <li v-if="ACCESS_MENU(REPORTS)" v-bind:class="{'active': [ ROUTE_REPORTS ].includes(currentPath) }"> 
                    <router-link to="/admin/reportes"><i :class="ACCESS_MENU(REPORTS).menu.icon"></i> <span>{{ ACCESS_MENU(REPORTS).menu.title }}</span></router-link>
                </li>
            </ul>
        </div>
    </vue-custom-scrollbar>
    </div>
</div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { ACCESS_MENU, ACCESS_MENU_ACTION } from '../../validations/auth/AccessControlList';
import { ADMIN_USERS_INDEX, CLIENT_USERS_INDEX, HOTEL_RESERVATIONS_INDEX,
        CIRCUIT_RESERVATIONS_INDEX,
        DAYTRIPS_RESERVATIONS_INDEX,
        TOURS_RESERVATIONS_INDEX,
        INTERNATIONAL_RESERVATIONS_INDEX,
        FLIGHT_RESERVATIONS_INDEX,
        OTHER_SERVICES_RESERVATIONS_INDEX,
        PACKAGED_RESERVATIONS_INDEX,
        LOCKED_RESERVATIONS_INDEX,
        GROUP_RESERVATIONS_INDEX,
} from '../../enums/MenuActionEnums';
import {HOME, BRANCH_OFFICE, USERS, OPERATORS, CONVEYORS, UNITS, SERVICES, RESERVATIONS, BOX, 
    SAVING, PROMOTIONS, BANKS, PROFILES, COUPON, PAYMENT_MULTIPLE, SERVICE_QUOTE, ACTIVITY, REPORTS} from "../../enums/MenuEnums";
import { 
    ROUTE_HOME,
    ROUTE_BRANCH_OFFICES,
    ROUTE_BRANCH_OFFICESADD,
    ROUTE_BRANCH_OFFICESEDIT,
    ROUTE_BRANCH_WORKINGHOURS,
    ROUTE_USER_ADMIN,
    ROUTE_USER_CLIENTS,
    ROUTE_USER_FREELANCERS,
    ROUTE_USER_AGENCIES,
    ROUTE_USER_ADD,
    ROUTE_USER_EDIT,
    ROUTE_OPERATORS,
    ROUTE_OPERATOR_EDIT,
    ROUTE_TRANSPORTS,
    ROUTE_TRANSPORT_EDIT,
    ROUTE_UNITS,
    ROUTE_SERVICE_HOTELS,
    ROUTE_SERVICE_CIRCUITS,
    ROUTE_SERVICE_DAYTRIPS,
    ROUTE_SERVICE_TOURS,
    ROUTE_SERVICE_INTERNATIONALS,
    ROUTE_SERVICE_LOCKEDS,
    ROUTE_SERVICE_LOCKED_SHOW,
    ROUTE_SERVICE_GROUPS,
    ROUTE_SERVICE_GROUP_SHOW,
    ROUTE_SERVICE_EDIT,
    ROUTE_SERVICE_TRAVEL_DATE,
    ROUTE_SERVICE_EXIT_PLACES,
    ROUTE_SERVICE_EXIT_PLACE_PRICES,
    ROUTE_RESERVATION_HOTEL_INDEX,
    ROUTE_RESERVATION_HOTEL_CREATE,
    ROUTE_RESERVATION_HOTEL_SHOW,
    ROUTE_RESERVATION_HOTEL_EDIT,
    ROUTE_RESERVATION_CIRCUIT_INDEX,
    ROUTE_RESERVATION_CIRCUIT_CREATE,
    ROUTE_RESERVATION_CIRCUIT_SHOW,
    ROUTE_RESERVATION_CIRCUIT_EDIT,
    ROUTE_RESERVATION_DAYTRIPS_INDEX,
    ROUTE_RESERVATION_DAYTRIPS_CREATE,
    ROUTE_RESERVATION_DAYTRIPS_SHOW,
    ROUTE_RESERVATION_DAYTRIPS_EDIT,
    ROUTE_RESERVATION_TOURS_INDEX,
    ROUTE_RESERVATION_TOURS_CREATE,
    ROUTE_RESERVATION_TOURS_SHOW,
    ROUTE_RESERVATION_TOURS_EDIT,
    ROUTE_USER_PERMISSIONS,
    ROUTE_USER_BRANCHOFFICES,
    ROUTE_RESERVATION_INTERNATIONALS_INDEX,
    ROUTE_RESERVATION_INTERNATIONALS_CREATE,
    ROUTE_RESERVATION_INTERNATIONALS_SHOW,
    ROUTE_RESERVATION_INTERNATIONALS_EDIT,
    ROUTE_RESERVATION_FLIGHTS_INDEX,
    ROUTE_RESERVATION_FLIGHTS_CREATE,
    ROUTE_RESERVATION_FLIGHTS_SHOW,
    ROUTE_RESERVATION_FLIGHTS_EDIT,
    ROUTE_RESERVATION_OTHER_SERVICE_INDEX,
    ROUTE_RESERVATION_OTHER_SERVICE_CREATE,
    ROUTE_RESERVATION_OTHER_SERVICE_SHOW,
    ROUTE_RESERVATION_OTHER_SERVICE_EDIT,
    ROUTE_RESERVATION_NOTES,
    ROUTE_RESERVATION_SELECT_SEAT_INDEX,
    ROUTE_PROFILE_LIST,
    ROUTE_PROFILE_ADD,
    ROUTE_PROMOTIONS,
    ROUTE_PROMOTION_ADD,
    ROUTE_PROMOTION_EDIT,
    ROUTE_COUPONS,
    ROUTE_COUPON_ADD,
    ROUTE_BANKS,
    ROUTE_BANK_ADD,
    ROUTE_BANK_EDIT,
    ROUTE_RESERVATION_PAYMENTS,
    ROUTE_RESERVATION_LIST_PAYMENTS,
    ROUTE_RESERVATION_PEOPLE,
    ROUTE_BRANCH_BOXES,
    ROUTE_BOX_HISTORY,
    ROUTE_BOX_HISTORY_MOVE_LIST,
    ROUTE_SAVING,
    ROUTE_BANK_HISTORY_MOVE_LIST,
    ROUTE_RESERVATION_PAYMENT_TO_BROOKER,
    ROUTE_PROMOTION_FOLDER,
    ROUTE_RESERVATION_PACKAGED_INDEX,
    ROUTE_RESERVATION_PACKAGED_CREATE,
    ROUTE_RESERVATION_PACKAGED_SHOW,
    ROUTE_RESERVATION_PACKAGED_EDIT,
    ROUTE_ACTIVITY,
    ROUTE_REPORTS,
    ROUTE_RESERVATION_TRANSFER_INDEX,
    ROUTE_RESERVATION_TRANSFER_CREATE,
    ROUTE_RESERVATION_TRANSFER_SHOW,
    ROUTE_RESERVATION_TRANSFER_EDIT,
    ROUTE_PAYMENT_MULTIPLE,
    ROUTE_PAYMENT_MULTIPLE_ADD,
    ROUTE_SERVICE_QUOTE,
    ROUTE_SERVICE_QUOTE_ADD,
    ROUTE_RESERVATION_LOCKEDS_INDEX,
    ROUTE_RESERVATION_LOCKEDS_CREATE,
    ROUTE_RESERVATION_LOCKEDS_SHOW,
    ROUTE_RESERVATION_LOCKEDS_EDIT,
    ROUTE_RESERVATION_GROUPS_INDEX,
    ROUTE_RESERVATION_GROUPS_CREATE,
    ROUTE_RESERVATION_GROUPS_SHOW,
    ROUTE_RESERVATION_GROUPS_EDIT,
 } from "../../enums/RouteNameEnums";
import { mapGetters } from "vuex";
import $ from 'jquery';
export default {
    components: {
        vueCustomScrollbar
    },
    data() {
        return {
            settings: {
                suppressScrollX: true,
            },
            config : {},
            activeClass: 'active',
            openMenu: true,
            menuActive: 0,

            //ACTIONS
            ADMIN_USERS_INDEX, CLIENT_USERS_INDEX, HOTEL_RESERVATIONS_INDEX,
            CIRCUIT_RESERVATIONS_INDEX,
            DAYTRIPS_RESERVATIONS_INDEX,
            TOURS_RESERVATIONS_INDEX,
            INTERNATIONAL_RESERVATIONS_INDEX,
            FLIGHT_RESERVATIONS_INDEX,
            OTHER_SERVICES_RESERVATIONS_INDEX,
            PACKAGED_RESERVATIONS_INDEX,
            LOCKED_RESERVATIONS_INDEX,
            GROUP_RESERVATIONS_INDEX,

            //MENU IDS
            HOME, BRANCH_OFFICE, USERS, OPERATORS, 
            CONVEYORS, UNITS, SERVICES, 
            RESERVATIONS, BOX, 
            SAVING, PROMOTIONS, BANKS, PROFILES, COUPON, PAYMENT_MULTIPLE, ACTIVITY, REPORTS,
            SERVICE_QUOTE,


            //ROUTE NAMES COMPONENTS
            ROUTE_HOME,
            ROUTE_BRANCH_OFFICES,
            ROUTE_BRANCH_OFFICESADD,
            ROUTE_BRANCH_OFFICESEDIT,
            ROUTE_BRANCH_WORKINGHOURS,
            ROUTE_USER_ADMIN,
            ROUTE_USER_CLIENTS,
            ROUTE_USER_FREELANCERS,
            ROUTE_USER_AGENCIES,
            ROUTE_USER_ADD,
            ROUTE_USER_EDIT,
            ROUTE_OPERATORS,
            ROUTE_OPERATOR_EDIT,
            ROUTE_TRANSPORTS,
            ROUTE_TRANSPORT_EDIT,
            ROUTE_UNITS,
            ROUTE_SERVICE_HOTELS,
            ROUTE_SERVICE_CIRCUITS,
            ROUTE_SERVICE_DAYTRIPS,
            ROUTE_SERVICE_TOURS,
            ROUTE_SERVICE_INTERNATIONALS,
            ROUTE_SERVICE_LOCKEDS,
            ROUTE_SERVICE_LOCKED_SHOW,
            ROUTE_SERVICE_GROUPS,
            ROUTE_SERVICE_GROUP_SHOW,
            ROUTE_SERVICE_EDIT,
            ROUTE_SERVICE_TRAVEL_DATE,
            ROUTE_SERVICE_EXIT_PLACES,
            ROUTE_SERVICE_EXIT_PLACE_PRICES,
            ROUTE_RESERVATION_HOTEL_INDEX,
            ROUTE_RESERVATION_HOTEL_CREATE,
            ROUTE_RESERVATION_HOTEL_SHOW,
            ROUTE_RESERVATION_HOTEL_EDIT,
            ROUTE_RESERVATION_CIRCUIT_INDEX,
            ROUTE_RESERVATION_CIRCUIT_CREATE,
            ROUTE_RESERVATION_CIRCUIT_SHOW,
            ROUTE_RESERVATION_CIRCUIT_EDIT,
            ROUTE_RESERVATION_DAYTRIPS_INDEX,
            ROUTE_RESERVATION_DAYTRIPS_CREATE,
            ROUTE_RESERVATION_DAYTRIPS_SHOW,
            ROUTE_RESERVATION_DAYTRIPS_EDIT,
            ROUTE_RESERVATION_TOURS_INDEX,
            ROUTE_RESERVATION_TOURS_CREATE,
            ROUTE_RESERVATION_TOURS_SHOW,
            ROUTE_RESERVATION_TOURS_EDIT,
            ROUTE_USER_PERMISSIONS,
            ROUTE_USER_BRANCHOFFICES,
            ROUTE_RESERVATION_INTERNATIONALS_INDEX,
            ROUTE_RESERVATION_INTERNATIONALS_CREATE,
            ROUTE_RESERVATION_INTERNATIONALS_SHOW,
            ROUTE_RESERVATION_INTERNATIONALS_EDIT,
            ROUTE_RESERVATION_FLIGHTS_INDEX,
            ROUTE_RESERVATION_FLIGHTS_CREATE,
            ROUTE_RESERVATION_FLIGHTS_SHOW,
            ROUTE_RESERVATION_FLIGHTS_EDIT,
            ROUTE_RESERVATION_OTHER_SERVICE_INDEX,
            ROUTE_RESERVATION_OTHER_SERVICE_CREATE,
            ROUTE_RESERVATION_OTHER_SERVICE_SHOW,
            ROUTE_RESERVATION_OTHER_SERVICE_EDIT,
            ROUTE_RESERVATION_SELECT_SEAT_INDEX,
            ROUTE_RESERVATION_NOTES,
            ROUTE_PROFILE_LIST,
            ROUTE_PROFILE_ADD,
            ROUTE_PROMOTIONS,
            ROUTE_PROMOTION_ADD,
            ROUTE_PROMOTION_EDIT,
            ROUTE_COUPONS,
            ROUTE_COUPON_ADD,
            ROUTE_BANKS,
            ROUTE_BANK_ADD,
            ROUTE_BANK_EDIT,
            ROUTE_RESERVATION_PAYMENTS,
            ROUTE_RESERVATION_LIST_PAYMENTS,
            ROUTE_RESERVATION_PEOPLE,
            ROUTE_BRANCH_BOXES,
            ROUTE_BOX_HISTORY,
            ROUTE_BOX_HISTORY_MOVE_LIST,
            ROUTE_SAVING,
            ROUTE_BANK_HISTORY_MOVE_LIST,
            ROUTE_RESERVATION_PAYMENT_TO_BROOKER,
            ROUTE_PROMOTION_FOLDER,
            ROUTE_RESERVATION_PACKAGED_INDEX,
            ROUTE_RESERVATION_PACKAGED_CREATE,
            ROUTE_RESERVATION_PACKAGED_SHOW,
            ROUTE_RESERVATION_PACKAGED_EDIT,
            ROUTE_ACTIVITY,
            ROUTE_REPORTS,
            ROUTE_RESERVATION_TRANSFER_INDEX,
            ROUTE_RESERVATION_TRANSFER_CREATE,
            ROUTE_RESERVATION_TRANSFER_SHOW,
            ROUTE_RESERVATION_TRANSFER_EDIT,
            ROUTE_PAYMENT_MULTIPLE,
            ROUTE_PAYMENT_MULTIPLE_ADD,
            ROUTE_SERVICE_QUOTE,
            ROUTE_SERVICE_QUOTE_ADD,
            ROUTE_RESERVATION_LOCKEDS_INDEX, 
            ROUTE_RESERVATION_LOCKEDS_CREATE,
            ROUTE_RESERVATION_LOCKEDS_SHOW,
            ROUTE_RESERVATION_LOCKEDS_EDIT,
            ROUTE_RESERVATION_GROUPS_INDEX, 
            ROUTE_RESERVATION_GROUPS_CREATE,
            ROUTE_RESERVATION_GROUPS_SHOW,
            ROUTE_RESERVATION_GROUPS_EDIT,
        };
        //  isactive : true
    },
    computed: {
        ...mapGetters("auth", ["user"]),
        ...mapGetters("config", ["configuration"]),
        
        currentPath() {
            return this.$route.name;
        }
    },
    mounted() {

        setTimeout(() => {
            const config = JSON.parse(localStorage.getItem('configuration'));
            if (config !== undefined && config !== null) {
                this.config = config;
            }
        }, 2000);
    },
    methods: {
        ACCESS_MENU,
        ACCESS_MENU_ACTION,
        scrollHanle() {
            // console.log(evt)
        },
        toggleMenu(menu){
            if(menu != this.menuActive && this.openMenu == true){
                this.menuActive = menu;
                return;
            }
            
            this.menuActive = menu;
            this.openMenu = this.openMenu ? false : true;
        }
    },
}
</script>
<style>
    .scroll-area {
        position: relative;
        margin: auto;
        height: calc(100vh - 60px);
        background-color: transparent !important;
    }
    .sidebar-menu ul li a.active {
        background-color: #ff0080 !important;
        color: #fff;
    }
    .sidebar-menu ul ul a.active {
        background-color: unset !important;
        color: #ff0080;
        text-decoration: underline;
    }
</style>