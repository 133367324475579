
import { HOME, BRANCH_OFFICE, USERS, OPERATORS, CONVEYORS, UNITS, SERVICES, RESERVATIONS, BOX, ACCOUNT_TO_PAY, SAVING, PROMOTIONS, BANKS, PROFILES, COUPON, PAYMENT_MULTIPLE, SERVICE_QUOTE, ACTIVITY, REPORTS } from "../enums/MenuEnums";

export const ROUTE_LOGIN                        = 'ginPage_L';
export const ROUTE_HOME                         = 'HomePage_'+HOME;
export const ROUTE_BRANCH_OFFICES               = 'BranchOfficesPage_'+BRANCH_OFFICE;
export const ROUTE_BRANCH_OFFICESADD            = 'BranchOfficesAddPage_'+BRANCH_OFFICE;
export const ROUTE_BRANCH_OFFICESEDIT           = 'BranchOfficesEditPage_'+BRANCH_OFFICE;
export const ROUTE_BRANCH_WORKINGHOURS          = 'BranchOfficesWorkingHoursPage_'+BRANCH_OFFICE;
export const ROUTE_USER_ADMIN                   = 'UserAdminPage_'+USERS;
export const ROUTE_USER_CLIENTS                 = 'UserClientsPage_'+USERS;
export const ROUTE_USER_FREELANCERS             = 'UserFreelancersPage_'+USERS;
export const ROUTE_USER_AGENCIES                = 'UserAgenciesPage_'+USERS;
export const ROUTE_USER_ADD                     = 'UserAddPage_'+USERS;
export const ROUTE_USER_EDIT                    = 'UserEditPage_'+USERS;
export const ROUTE_OPERATORS                    = 'OperatorsPage_'+OPERATORS;
export const ROUTE_OPERATOR_EDIT                = 'OperatorEditPage_'+OPERATORS;
export const ROUTE_TRANSPORTS                   = 'TransportsPage_'+CONVEYORS;
export const ROUTE_TRANSPORT_EDIT               = 'TransportEditPage_'+CONVEYORS;
export const ROUTE_UNITS                        = 'UnitsPage_'+UNITS;
export const ROUTE_SERVICE_HOTELS               = 'ServiceHotelsPage_'+SERVICES;
export const ROUTE_SERVICE_CIRCUITS             = 'ServiceCircuitsPage_'+SERVICES;
export const ROUTE_SERVICE_DAYTRIPS             = 'ServiceDaytripsPage_'+SERVICES;
export const ROUTE_SERVICE_TOURS                = 'ServiceTours_'+SERVICES;
export const ROUTE_SERVICE_INTERNATIONALS       = 'ServiceInternationalsPage_'+SERVICES;
export const ROUTE_SERVICE_LOCKEDS              = 'ServiceLockedsPage_'+SERVICES;
export const ROUTE_SERVICE_LOCKED_SHOW          = 'ServiceLockedShowPage_'+SERVICES;
export const ROUTE_SERVICE_GROUPS               = 'ServiceGroupsPage_'+SERVICES;
export const ROUTE_SERVICE_GROUP_SHOW           = 'ServiceGroupShowPage_'+SERVICES;
export const ROUTE_SERVICE_EDIT                 = 'ServiceEditPage_'+SERVICES;
export const ROUTE_SERVICE_TRAVEL_DATE          = 'ServiceTravelDatePage_'+SERVICES;
export const ROUTE_SERVICE_EXIT_PLACES          = 'ServiceExitPlacesPage_'+SERVICES;
export const ROUTE_SERVICE_EXIT_PLACE_PRICES    = 'ServiceExitPlacePricesPage_'+SERVICES;
export const ROUTE_SERVICE_TRAVEL_UNIT          = 'ServiceTravelUnitPage_'+SERVICES;
export const ROUTE_RESERVATION_HOTEL_INDEX      = 'ReservationHotelIndexPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_HOTEL_CREATE     = 'ReservationHotelCreatePage_'+RESERVATIONS;
export const ROUTE_RESERVATION_HOTEL_SHOW       = 'ReservationHotelShowPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_HOTEL_EDIT       = 'ReservationHotelEditPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_CIRCUIT_INDEX    = 'ReservationCircuitIndexPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_CIRCUIT_CREATE   = 'ReservationCircuitCreatePage_'+RESERVATIONS;
export const ROUTE_RESERVATION_CIRCUIT_SHOW     = 'ReservationCircuitShowPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_CIRCUIT_EDIT     = 'ReservationCircuitEditPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_DAYTRIPS_INDEX   = 'ReservationDaytripsIndexPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_DAYTRIPS_CREATE  = 'ReservationDaytripsCreatePage_'+RESERVATIONS;
export const ROUTE_RESERVATION_DAYTRIPS_SHOW    = 'ReservationDaytripsShowPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_DAYTRIPS_EDIT    = 'ReservationDaytripsEditPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_TOURS_INDEX      = 'ReservationToursIndexPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_TOURS_CREATE     = 'ReservationToursCreatePage_'+RESERVATIONS;
export const ROUTE_RESERVATION_TOURS_SHOW       = 'ReservationToursShowPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_TOURS_EDIT       = 'ReservationToursEditPage_'+RESERVATIONS;
export const ROUTE_USER_PERMISSIONS             = 'UserPermissionsPage_'+USERS;
export const ROUTE_USER_BRANCHOFFICES           = 'UserBranchOfficesPage_'+USERS;
export const ROUTE_RESERVATION_INTERNATIONALS_INDEX     = 'ReservationInternationalsIndexPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_INTERNATIONALS_CREATE    = 'ReservationInternationalsCreatePage_'+RESERVATIONS;
export const ROUTE_RESERVATION_INTERNATIONALS_SHOW      = 'ReservationInternationalsShowPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_INTERNATIONALS_EDIT      = 'ReservationInternationalsEditPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_FLIGHTS_INDEX        = 'ReservationFlightsIndexPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_FLIGHTS_CREATE       = 'ReservationFlightsCreatePage_'+RESERVATIONS;
export const ROUTE_RESERVATION_FLIGHTS_SHOW         = 'ReservationFlightsShowPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_FLIGHTS_EDIT         = 'ReservationFlightsEditPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_OTHER_SERVICE_INDEX      = 'ReservationOtherServicesIndexPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_OTHER_SERVICE_CREATE     = 'ReservationOtherServicesCreatePage_'+RESERVATIONS;
export const ROUTE_RESERVATION_OTHER_SERVICE_SHOW       = 'ReservationOtherServicesShowPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_OTHER_SERVICE_EDIT       = 'ReservationOtherServicesEditPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_SELECT_SEAT_INDEX        = 'ReservationSelectSeatPeoplePage_'+RESERVATIONS;
export const ROUTE_RESERVATION_PAYMENT_TO_BROOKER       = "ReservationPaymentToBrookerPage_"+RESERVATIONS;
export const ROUTE_RESERVATION_PAYMENT_TO_BROOKER_VIEW  = "ReservationPaymentToBrookerViewPage_"+RESERVATIONS;

export const ROUTE_RESERVATION_TRANSFER_INDEX      = 'ReservationTransferIndexPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_TRANSFER_CREATE     = 'ReservationTransferCreatePage_'+RESERVATIONS;
export const ROUTE_RESERVATION_TRANSFER_SHOW       = 'ReservationTransferShowPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_TRANSFER_EDIT       = 'ReservationTransferEditPage_'+RESERVATIONS;

export const ROUTE_RESERVATION_PASS_INDEX           = 'ReservationPassPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_NOTES                = 'ReservationNotesPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_PEOPLE               = 'ReservationPeoplePage_'+RESERVATIONS;
export const ROUTE_RESERVATION_PAYMENTS             = 'ReservationPaymentsPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_LIST_PAYMENTS        = 'ReservationListPaymentsPage_'+RESERVATIONS;

export const ROUTE_RESERVATION_CANCEL               = 'ReservationCancelPage_'+RESERVATIONS;

export const ROUTE_PROFILE_LIST                 = 'ProfilePage_'+PROFILES;
export const ROUTE_PROFILE_ADD                  = 'ProfileAddPage_'+PROFILES;
export const ROUTE_PROFILE_EDIT                 = 'ProfileEditPage_'+PROFILES;

export const ROUTE_PROMOTIONS           = "PromotionPage_"+PROMOTIONS;
export const ROUTE_PROMOTION_ADD        = "PromotionAddPage_"+PROMOTIONS;
export const ROUTE_PROMOTION_EDIT       = "PromotionEditPage_"+PROMOTIONS;

export const ROUTE_COUPONS           = "CouponPage_"+COUPON;
export const ROUTE_COUPON_ADD        = "CouponAddPage_"+COUPON;
export const ROUTE_COUPON_EDIT       = "CouponEditPage_"+COUPON;

export const ROUTE_BANKS            = "BankPage_"+BANKS;
export const ROUTE_BANK_ADD         = "BankAddPage_"+BANKS;
export const ROUTE_BANK_EDIT        = "BankEditPage_"+BANKS;
export const ROUTE_BANK_HISTORY_MOVE_LIST   = "BankHistoryMoveListPage_"+BANKS;

export const ROUTE_BRANCH_BOXES     = "BranchBoxesPage_"+BOX;
export const ROUTE_BOX_HISTORY      = "BoxCutPage_"+BOX;
export const ROUTE_BOX_HISTORY_MOVE_LIST = "BoxHistoryMoveListPage_"+BOX;

export const ROUTE_SAVING           = "SavingPage_"+SAVING;

export const ROUTE_CONFIGURATIONS   = "ConfigurationPage_"+HOME;

export const ROUTE_PROFILE          = "ProfilePage_"+HOME;

export const ROUTE_PROMOTION_FOLDER = "PromotionFolderPage_"+PROMOTIONS;

export const ROUTE_RESERVATION_PACKAGED_INDEX       = "ReservationPackagedIndex_"+RESERVATIONS;
export const ROUTE_RESERVATION_PACKAGED_CREATE      = "ReservationPackagedCreate_"+RESERVATIONS;
export const ROUTE_RESERVATION_PACKAGED_SHOW        = "ReservationPackagedShow_"+RESERVATIONS;
export const ROUTE_RESERVATION_PACKAGED_EDIT        = "ReservationPackagedEdit_"+RESERVATIONS;


/**ROUTE CLIENT  */
export const ROUTE_CLIENT_HOME          = "ClientHomePage_client";
export const ROUTE_CLIENT_LOGIN         = "ClientLoginPage_client";
export const ROUTE_CLIENT_TEMPLATE      = "ClientTemplatePage_client";
export const ROUTE_CLIENT_DASHBOARD     = "ClientDashboardPage_client";
export const ROUTE_CLIENT_RESERVATIONS  = "ClientReservationsPage_client";
export const ROUTE_CLIENT_PROFILE       = "ClientProfilePage_client";
export const ROUTE_CLIENT_PAYMENTS      = "ClientPaymentsPage_client";
//export const ROUTE_CLIENT_HOME  = "ClientHomePage_client";

export const ROUTE_ACTIVITY = "ActivitiesPage_"+ACTIVITY;

export const ROUTE_REPORTS = "ReportsPage_"+REPORTS;

export const ROUTE_BRANCH_OFFICE_GALLERY = "BranchOfficesGallery_"+BRANCH_OFFICE;
export const ROUTE_BRANCH_OFFICE_TEAM = "BranchOfficesTeam_"+BRANCH_OFFICE;

export const ROUTE_PAYMENT_MULTIPLE = "PaymentMultiplePage_"+PAYMENT_MULTIPLE;
export const ROUTE_PAYMENT_MULTIPLE_ADD = "PaymentMultipleAddPage_"+PAYMENT_MULTIPLE;
export const ROUTE_PAYMENT_MULTIPLE_SHOW = "PaymentMultipleShowPage_"+PAYMENT_MULTIPLE;

export const ROUTE_SERVICE_QUOTE = "ServiceQuotePage_"+SERVICE_QUOTE;
export const ROUTE_SERVICE_QUOTE_ADD = "ServiceQuoteAddPage_"+SERVICE_QUOTE;
export const ROUTE_SERVICE_QUOTE_EDIT = "ServiceQuoteEditPage_"+SERVICE_QUOTE;
export const ROUTE_SERVICE_QUOTE_SHOW = "ServiceQuoteShowPage_"+SERVICE_QUOTE;

export const ROUTE_RESERVATION_LOCKEDS_INDEX    = 'ReservationLockedsIndexPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_LOCKEDS_CREATE   = 'ReservationLockedsCreatePage_'+RESERVATIONS;
export const ROUTE_RESERVATION_LOCKEDS_SHOW     = 'ReservationLockedsShowPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_LOCKEDS_EDIT     = 'ReservationLockedsEditPage_'+RESERVATIONS;

export const ROUTE_RESERVATION_GROUPS_INDEX    = 'ReservationGroupsIndexPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_GROUPS_CREATE   = 'ReservationGroupsCreatePage_'+RESERVATIONS;
export const ROUTE_RESERVATION_GROUPS_SHOW     = 'ReservationGroupsShowPage_'+RESERVATIONS;
export const ROUTE_RESERVATION_GROUPS_EDIT     = 'ReservationGroupsEditPage_'+RESERVATIONS;