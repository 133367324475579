import axios from "axios";
import { USER_ADMIN } from "@/enums/UserTypeEnums";

export default {
    namespaced: true,

    state: {
        userData: null,
        menuData: null,
        profileData: null,
        actionData: null,
    },

    getters: {
        user: state => state.userData,
        menu: state => state.menuData,
        profile: state => state.profileData,
        actions: state => state.actionData,
    },

    mutations: {
        setUserData(state, user) {
            state.userData = user == null ? null : user.user;
            state.menuData = user == null ? null :  user.menus;
            state.profileData = user == null ? null : user.profile;
            state.actionData = user == null ? null : user.actions;
        }
    },

    actions: {
        getUserData({ commit }) {
            const config = JSON.parse(localStorage.getItem('configuration'));
            axios
            .get(`/${config.identifier}/user`)
            .then(response => {
                commit("setUserData", response.data);
            })
            .catch(() => {
                localStorage.removeItem("authToken");
            });
        },

        sendLoginRequest({ commit }, data) {
            commit("setErrors", {}, { root: true });
            const config = JSON.parse(localStorage.getItem('configuration'));
            return axios
            .post(`${config.identifier}/login`, data)
            .then(response => {
                localStorage.setItem("userNumber", response.data.data.users.id);
                if(response.data.data.users.user_type == USER_ADMIN){
                    commit("setUserData", response.data);
                    localStorage.setItem("authToken", response.data.data.access_token);
                }else{
                    localStorage.setItem("authToken", response.data.data.access_token);
                    localStorage.setItem("userType", JSON.stringify(response.data.data.users));
                }
                
            }).catch(error =>{
                console.log(error);
            });
        },

        forgotPassword({ commit }, data) {
            commit("setErrors", {}, { root: true });
            const config = JSON.parse(localStorage.getItem('configuration'));
            return axios
            .post(`${config.identifier}/password-reset`, data)
            .then(response => {
                localStorage.setItem("message", JSON.stringify(response.data.message));
            }).catch(error =>{
                console.log(error);
            });
        },

        sendRegisterRequest({ commit }, data) {
        commit("setErrors", {}, { root: true });
        return axios
            .post(process.env.VUE_APP_API_URL + "register", data)
            .then(response => {
            commit("setUserData", response.data.user);
            localStorage.setItem("authToken", response.data.token);
            });
        },

        sendLogoutRequest({ commit }) {
            const config = JSON.parse(localStorage.getItem('configuration'));
            axios.post(`/${config.identifier}/logout`).then(() => {
                commit("setUserData", null);
                localStorage.removeItem("authToken");
                localStorage.removeItem("userType");
                localStorage.removeItem("userNumber");
            });
        },

        sendVerifyResendRequest() {
        return axios.get(process.env.VUE_APP_API_URL + "email/resend");
        },
        sendVerifyRequest({ dispatch }, hash) {
        return axios
            .get(process.env.VUE_APP_API_URL + "email/verify/" + hash)
            .then(() => {
            dispatch("getUserData");
            });
        }
    }
};
