<template>
    <div class="pt-3">
        <ul class="pagination pagination-sm mb-0 justify-content-center">
            <li v-if="page.current_page <= 1" class="page-item disabled">
                <a class="page-link" href="javascript:void(0)" tabindex="-1">
                    <i class="fa fa-arrow-left"></i>
                </a>
            </li>
            <li v-else class="page-item">
                <a class="page-link" href="javascript:void(0)" v-on:click.prevent="changePage(page.current_page - 1)" tabindex="-1">
                    <i class="fa fa-arrow-left"></i>
                </a>
            </li>
            <li class="page-item" v-for="paginate in pagesNumber" 
                v-bind:key="paginate.id" :class="{'active': paginate == page.current_page}">
                <a :class="`page-link ${ paginate == page.current_page ? 'disabled' : ''}`" href="javascript:void(0)" 
                    v-on:click.prevent="changePage(paginate)"> {{ paginate }} 
                </a>
            </li>
            <li class="page-item" v-if="page.current_page < page.last_page">
                <a class="page-link" href="javascript:void(0)" v-on:click.prevent="changePage(page.current_page + 1)">
                    <i class="fa fa-arrow-right"></i>
                </a>
            </li>
            <li v-else class="page-item disabled">
                <a class="page-link" href="javascript:void(0)">
                    <i class="fa fa-arrow-right"></i>
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
export default{
    name    : "TablePagination",
    props   : [ 'page', 'offset' ],
    // {
    //     page: {
    //         type: Object,
    //         required: true
    //     },
    //     offset: {
    //         type: Number,
    //         default: 4
    //     }
    // },
    data(){
        return {
            //pagination  : this.page,
        }
    },
    computed: {
        pagesNumber() {
            if (!this.page.to) {
                return [];
            }
            let from = this.page.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            let to = from + (this.offset * 2);
            if (to >= this.page.last_page) {
                to = this.page.last_page;
            }
            let pagesArray = [];
            for (let page = from; page <= to; page++) {
                pagesArray.push(page);
            }
            return pagesArray;
        }
    },
    methods : {
        changePage(item) {
            //this.page.current_page = item;
            //this.$router.push('?page='+item);
            this.$emit('paginate', item);
        }
    }
}
</script>