<template>
    <div class="page-header">
        <div class="row">
            <div class="col-lg-10">
                <h4 class="page-title">{{ title }}</h4>
                <BreadcrumbSub :items="items" />
                <ul class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                    <li class="breadcrumb-item active">{{ title }}</li>
                </ul>
            </div>
            <div class="col-lg-2 text-end">
                <BackPage ref="btn_back_page" />
            </div>
        </div>
    </div>
</template>
<script>
import BackPage from './BackPage.vue';
import BreadcrumbSub from './BreadcrumbSub.vue';
export default {
    name        : 'BreadcrumbPage',
    props       : [ 'title', 'items' ],
    data(){
        return {
        }
    },
    components  : {
        BackPage,
        BreadcrumbSub,
    }
}
</script>