import axios from "axios";

export default {
    namespaced: true,

    state: {
        configurationData: null,
    },

    getters: {
        configuration: state => state.configurationData,
    },

    mutations: {
        setConfigurationData(state, config) {
            state.configurationData = config.data;
        }
    },

    actions: {
        getConfigurationData({ commit }) {
            const config = JSON.parse(localStorage.getItem('configuration'));
            axios
            .get(`/${config.identifier}/system-configurations`)
            .then(response => {
                commit("setConfigurationData", response.data);
            })
            .catch(() => {
                //localStorage.removeItem("authToken");
            });
        },
    }
};
